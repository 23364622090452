import SelectMenu from "../../../components/SelectMenu"
import Calendar from "../../../components/Calender"
import { useEffect, useState } from "react"
import ApiService from "../../../services/ApiService"
import { useDispatch } from "react-redux"
import { addError } from "../../../redux/pageErrorHandler"
import Loader from "../../../components/Loader"
import Table from "../../../components/Table/Table"
import Button from "../../../components/Button"
import { useNavigate } from "react-router-dom"

const EventRegistrations = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [events, setEvents] = useState({ loading: false, errror: false, message: "", data: [] })
  const [eventRegistrations, setEventRegistration] = useState({
    loading: false,
    errror: false,
    message: "",
    data: [],
    completed: false,
  })
  const [selectEvtErr, setSelectEvtErr] = useState(null)
  const [eventId, setEventId] = useState(null)
  const [eventType, setEventType] = useState("")

  const [curr_event_type, set_cur_event_type] = useState("")

  const skillLevelMap = [
    { name: '1.0 - 2.5 [Beginner]', value: 1 }, 
    { name: '2.5 - 3.5 [Medium]', value: 2 }, 
    { name: '4.0 + [Advanced]', value: 3 }
  ]

  const getSkillLevel = (skillLevel) => {
    if (!skillLevel) return "-"
    const skillLevelItem = skillLevelMap.filter(s => s.value == skillLevel)[0]
    if (skillLevelItem) {
      return skillLevelItem.name
    }
    return "-"
  }

  const columns = [
    {
      name: "Name",
      selector: "name",
      cell: (row) => <span>{row.name}</span>,
    },
    {
      name: "Team Name",
      selector: "teamName",
      cell: (row) => (
        <span>
          {row.teamName !== "-" && (
            <div className="bg-yellow-200 px-2 ml-1 py-1 rounded text-yellow-800 inline-block">
              {row.teamName}
            </div>
          )}
          {row.teamName === "-" && <div>{row.teamName}</div>}
        </span>
      ),
    },
    {
      name: "Registered Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Event Name",
      selector: "eventName",
    },
    {
      name: "Team Type",
      selector: "teamType",
    },
    {
      name: "Alternate Phone",
      selector: "alternatePhone",
    },
    {
      name: "Payment Status",
      selector: "paymentStatus",
      cell: (row) => (
        <>
          {row?.paymentStatus === "COMPLETED" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {row?.paymentStatus}
            </p>
          )}
          {row?.paymentStatus === "PENDING" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
              {row?.paymentStatus}
            </p>
          )}
          {row.paymentStatus === "DENIED" && (
            <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              {row.paymentStatus}
            </p>
          )}
        </>
      ),
    },
    curr_event_type === 'badminton' && { ...{
      name: "Skill Level",
      selector: "skillLevel",
      cell: (row) => (
        <>
          {getSkillLevel(row?.skillLevel)}
        </>
      )
    } },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <>
          {(curr_event_type === "cricket" || curr_event_type === "volleyball" || curr_event_type === "badminton") && (
            // <button on>View Team</button>
            <button
              onClick={() => navigate(`/view-team/${row._id}`)}
              className="inline justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-none"
            >
              View Team
            </button>
          )}
        </>
      ),
    },
  ]

  useEffect(() => {
    getAllEvents()
  }, [])

  const getAllEvents = async () => {
    try {
      setEvents({ loading: true, errror: false, message: "", data: [] })
      let res = await ApiService.getAllEvents({ filter: 'all' })
      let eventsItems = res.data?.events.map((evts) => {
        return {
          name: evts["eventName"],
          value: evts["_id"],
          eventType: evts.eventType["value"],
        }
      })
      setEvents((prev) => ({
        ...prev,
        loading: false,
        data: eventsItems,
      }))
    } catch (error) {
      let message = error.response.data.message
      dispatch(addError(message))
      setEvents((prev) => ({
        ...prev,
        loading: false,
        message: message,
        errror: true,
      }))
    }
  }

  const getEventRegistrations = async () => {
    if (eventId === null) {
      setSelectEvtErr("Please select an event.")
      return
    }

    set_cur_event_type(eventType !== "" ? eventType.trim() : "")

    try {
      setEventRegistration({
        loading: true,
        errror: false,
        message: "",
        data: [],
        completed: false,
      })
      let res = await ApiService.getEventRegistrations(eventId)
      let eventRegs = []
      res.data?.eventRegs.forEach((it) => {
        console.log(it)
        eventRegs.push({
          _id: it["_id"],
          name: it["primaryContactName"] ? it["primaryContactName"] : it["captainName"],
          email: it["email"],
          phone: it["phone"],
          eventName: it["event"]["eventName"],
          teamType: it["teamType"] || "-",
          alternatePhone: it["alternatePhone"],
          paymentStatus: it["payment"]["paymentStatus"] || "-",
          teamName: it["teamName"] || "-",
          skillLevel: it["skillLevel"] ?? "-"
        })
      })
      setEventRegistration((prev) => ({
        ...prev,
        loading: false,
        data: eventRegs,
        completed: true,
      }))
    } catch (error) {
      let message = error.response.data.message
      dispatch(addError(message))
      setEventRegistration((prev) => ({
        ...prev,
        loading: false,
        message: message,
        errror: true,
        completed: true,
      }))
    }
  }

  return (
    <section>
      <Loader open={events.loading || eventRegistrations.loading} />

      <header className="bg-white shadow">
        <div className="mx-auto py-3 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900">Event Registrations</h1>
        </div>
      </header>

      <div className="mx-8 my-4">
        <div className="max-w-[20rem]">
          <SelectMenu
            label="Choose an event"
            items={events.data}
            value={eventId}
            onChange={(data) => {
              setEventType(data.eventType)
              setEventId(data.value)
              setSelectEvtErr(null)
            }}
            hasError={selectEvtErr}
            error={selectEvtErr}
          />

          <Button className="mt-3" onClick={getEventRegistrations}>
            Show List
          </Button>
        </div>

        <Table columns={columns} data={eventRegistrations.data} />
      </div>
    </section>
  )
}

export default EventRegistrations
